<template>
    <template v-if="paginationMode === 'auto-lazy'">
        <div v-if="errorPagination" class="alert alert-danger error-get-page-product-list" role="alert">
            {{ $t('messages.errorToGetNextPage') }}
        </div>
        <div v-if="currentPage < totalPages" class="d-flex justify-content-center spinner-get-page">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </template>
</template>
<script>
export default {
    name: 'Pagination',
    props: {
        currentPage: {
            type: Number,
            default: 1
        },
        paginationMode: {
            type: String,
            default: 'auto-lazy'
        },
        totalPages: {
            type: Number,
            default: 1
        },
        errorPagination: {
            type: Boolean,
            default: false
        }
    }
}
</script>