<template>
    <template v-if="cart?.items?.length">
        <table class="shopping-cart-table" :data-quantities="quantities()">
            <thead>
            <tr>
                <th class="img" scope="col">&nbsp;</th>
                <th class="item" scope="col"><span>{{ $t('labels.product') }}</span></th>
                <th class="ref" scope="col"><span>{{ $t('labels.description') }}</span></th>
                <th class="price" scope="col"><span>{{ $t('labels.unitPrice') }}</span></th>
                <th class="qty" scope="col">{{ $t('labels.quantity') }}</th>
                <th class="subtotal" scope="col"><span>{{ $t('labels.subTotal') }}</span></th>
                <th class="action" scope="col">&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in cart.items" v-bind:data-id="item.id"
                :class="'cart-row' + ' row-' + index + (item.choice.bcl ? ' bcl-item' : '')">
                <td class="image"><img :src="item.images.find(img => img.type === 'main').src" class="img-fluid product-image"/></td>
                <td class="item">{{ item.name }}</td>
                <td class="choice-name">
                    <span class="choice-description">
                        {{ item.choice.description }}
                        <template v-if="item.choice.bcl && item.choice.maxTermNumber > 1">
                            <br>
                            <small class="choice-multiple-payment">
                                <b>{{ formatPrice(item.choice.startingPrice) }}&euro;</b>&nbsp;{{ $t('labels.then').toLowerCase() }}&nbsp;{{ item.choice.maxTermNumber - 1 }}&nbsp;x&nbsp;{{ formatPrice(item.choice.defaultPrice) }}&euro; /{{ item.choice.renewalTermLength > 1 ? `${item.choice.renewalTermLength}${$t('labels.months')}` : $t('labels.month') }}
                            </small>
                        </template>
                    </span>
                </td>
                <td class="price">
                    <span class="price-container">
                        <span v-if="item.choice.oldPrice" class="old-price-wrapper">
                            <span class="old-price">
                                <span class="amount">{{ formatPrice(item.choice.oldPrice) }}</span>
                                <span class="currency">&euro;</span>
                            </span>
                        </span>
                        <span class="price" v-if="!item.choice.isSingleProduct && item.choice.startingPrice">
                            <span class="starting-offer">
                            <span class="amount">{{ formatPrice(item.choice.startingPrice) }}</span>
                                <span class="currency">&euro;</span>
                                <span class="starting-offer-detail">{{ displayDuration(item.choice.startingPriceDuration, true) }}</span>
                            </span>
                            <br>
                            <span class="after-starting-offer">
                                <span class="after-starting-offer-details">{{ $t('labels.then') }}&nbsp;</span>
                                <span class="amount">{{ formatPrice(item.choice.unitPrice) }}</span>
                                <span class="currency">&euro;</span>
                                <span class="after-starting-offer-details"> / {{ displayDuration(item.choice.renewalTermLength) }}</span>
                            </span>
                        </span>
                        <span class="price" v-else>
                            <span class="amount">{{ formatPrice(item.choice.unitPrice) }}</span>
                            <span class="currency">&euro;</span>
                        </span>
                    </span>
                </td>
                <td class="qty">
                    <span v-if="viewonly || !shopAllowQtyModification || item.isFromCoupon">{{ item.qty }}</span>
                    <input v-else type="number" v-model="item.qty" min="1" step="1" v-on:blur="validateQty(item, $event)"/>
                </td>
                <td class="subtotal">
                    <span>{{ formatPrice((item.defaultSubtotal ? item.defaultSubtotal : item.subtotal)) }}</span>
                    <span class="currency">&euro;</span>
                </td>
                <td v-if="!viewonly && !item.isFromCoupon" class="action">
                    <button type="button" name="deleteItem" @click.prevent="deleteItemAction(item)"
                            class="delete-item-btn">{{ $t('labels.deleteItem') }}
                    </button>
                </td>
                <td v-if="!item.isFromCoupon && associatedBcls && associatedBcls[item.choice.id]?.length && !this.viewonly" class="bcl-suggestion">
                      <template v-if="associatedBcls && associatedBcls[item.choice.id]?.length && !this.viewonly">
                          <span class="associated-bcl-label">{{ $t('labels.suggestion') }}</span>
                          <select name="selectedAssociatedBcl"
                                  v-if="selectedAssociatedBcl[item.choice.id]"
                                  v-model="selectedAssociatedBcl[item.choice.id]">
                              <option v-for="associatedBcl in associatedBcls[item.choice.id]"
                                      :value="associatedBcl.id">{{ associatedBcl.description }}
                              </option>
                          </select>
                          <div v-if="selectedAssociatedBcl[item.choice.id]" class="add-to-cart-bcl"
                               :data-bcl="selectedAssociatedBcl[item.choice.id]">
                              <AddToCart :item="item"
                                         :changeChoice="findBcl(item.choice.id, selectedAssociatedBcl[item.choice.id])"
                                         :btnlabel="$t('labels.chooseBcl')"/>
                          </div>
                      </template>
                  </td>
            </tr>
            <tr id="oneclick-choice-div" data-id="oneclick-choice" v-if="checkoutMode && !has1ClickOptOut && oneClickOptOut" class="cart-row">
                <td class="image"><img :src="this.oneClickOptoutImage" class="img-fluid product-image"/></td>
                <td class="item">
                    <div class="item-wrapper">
                        <span class="item-content" v-html="renderHTML(this.oneClickOptoutLabel)"></span>
                        <span class="item-content-action">
                           <button type="button" name="deleteItem" @click.prevent="delete1ClickChoice()"
                                   class="delete-item-btn">{{ $t('labels.deleteItem') }}
                            </button>
                        </span>
                    </div>
                </td>
                <td class="choice-name">
                    <span class="choice-description"></span>
                </td>
                <td class="price">
                    <span class="price-container">
                        <span class="price">
                            <span class="amount">{{ pricingOfferPremium }}</span>
                            <span class="currency">&euro;</span>
                        </span>
                    </span>
                </td>
                <td class="qty">
                    <span>1</span>
                </td>
                <td class="subtotal">
                    <span>{{ pricingOfferPremium }}</span>
                    <span class="currency">&euro;</span>
                </td>
            </tr>
            </tbody>
        </table>
    </template>
    <template v-else>
        {{ $t('labels.noCartItems') }}
    </template>
</template>

<script>
import {useCartStore} from '@/store/cart.js';
import CartService from "@/services/cart";
import AddToCart from "@/components/AddToCart.vue";
import Shipping from "@/services/shipping";
import Utils from "@/services/utils";
import Price from "@/services/price";
import {useDraftStore} from "@/store/draft";

export default {
    name: 'CartTable',
    components: {AddToCart},
    props: {
        viewonly: Boolean
    },
    data() {
        return {
            associatedBcls: {}, // map of choice in cart and associated bcls
            selectedAssociatedBcl: {}, // selected bcl id indexed by its intitial choice id
            lastCheckedCartSignature: null,
            shopAllowQtyModification: false,
            oneClickOptoutImage: '',
            oneClickOptoutLabel: '',
            oneClickOptOut: false,
            hidePriceDecimals: false,
        }
    },
    computed: {
        cart() {
            return useCartStore().cart;
        },
        loading() {
            return useCartStore().isCartLoading;
        },
        checkoutMode() {
           return window.location.pathname.split('/').pop() === 'checkout';
        },
        has1ClickOptOut() {
            return useDraftStore().has1ClickOptOut;
        },
        pricingOfferPremium() {
            return this.hidePriceDecimals ? '0' : '0.00';
        }
    },
    watch: {
        loading: {
            handler(newValue, oldValue) {
                this.$nextTick(() => {
                    this.addCartUpdateClass(newValue);
                });
            }
        },
        cart: {
            handler(newValue, oldValue) {
                if (this.lastCheckedCartSignature === useCartStore().calcSignature()) {
                    return;
                }
                this.lastCheckedCartSignature = useCartStore().calcSignature();

                this.$nextTick(() => {
                    this.initAssociativeBcls();
                    CartService.updateCart();
                });
            },
            deep: true
        }
    },
    mounted() {
        this.oneClickOptoutImage = window.globalConfig.oneClickOptoutImage;
        this.oneClickOptoutLabel = window.globalConfig.oneClickOptoutLabel;
        this.oneClickOptOut = window.globalConfig.oneClickOptOut;
        this.shopAllowQtyModification = window.globalConfig.shopAllowQtyModification;
        this.hidePriceDecimals = window.globalConfig.hidePriceDecimals;
        this.lastCheckedCartSignature = useCartStore().calcSignature();
        this.initAssociativeBcls();
    },
    methods: {
        validateQty(item, event) {
          const value = event.target.value

          if(isNaN(value) || value < 0) {
            item.qty = 1;
            return;
          }

          item.qty = Math.floor(value)
        },
        deleteItemAction(item) {
            CartService.deleteItem(item);
        },
        delete1ClickChoice() {
            useDraftStore().updateHas1ClickOptOut(true);
        },
        quantities() {
            return Shipping.getQuantities(useCartStore().cart.items);
        },
        initAssociativeBcls() {
            for (const item of this.cart.items) {
                if (!this.associatedBcls[item.choice.id]) {
                    const bcls = this.associatedBclsToDisplay(item);
                    this.associatedBcls[item.choice.id] = bcls;
                    if (bcls.length) {
                        this.selectedAssociatedBcl[item.choice.id] = bcls[0].id;
                    }
                }
            }
        },
        associatedBclsToDisplay(currentCartItem) {
            let associatedBclsToDisplay = [];
            if (currentCartItem.choice.bcl || !currentCartItem.choices) {
                return associatedBclsToDisplay;
            }

            const choicesInCart = [];
            for (const cartItem of this.cart.items) {
                choicesInCart.push(cartItem.choice.id);
            }

            associatedBclsToDisplay = currentCartItem.choices.filter((item) => {
                // find for current cart product, alternative choices that are bcl and not already in cart
                return item.bcl && !choicesInCart.includes(item.id) && item.show === true;
            });

            associatedBclsToDisplay.sort((bcl1, bcl2) => {
                return bcl1.unitPrice - bcl2.unitPrice;
            });

            return associatedBclsToDisplay;
        },
        findBcl(initialChoiceId, selectedBclChoiceId) {
            const cartItem = this.cart.items.find(item => item.choice.id === initialChoiceId);
            return cartItem.choices.find(item => item.id === selectedBclChoiceId);
        },
        addCartUpdateClass(value) {
            const cartElements = document.querySelectorAll('.cart');
            for (const cartElement of cartElements) {
                if (value) {
                    cartElement.classList.add('updating');
                } else {
                    cartElement.classList.remove('updating');
                }
            }
        },
        displayDuration(duration, startingPrice = false) {
            return Utils.displayDuration(duration, this.$i18n.locale, startingPrice);
        },
        formatPrice(price) {
            return Price.format(price);
        }
    }
}
</script>
